import { faComment, faHeart, faDonate } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback, useMemo, useRef } from "react"
import { useModal } from "../../hooks/use-modal"
import { useStyle } from "../../hooks/use-style"
import { lightTheme, darkTheme } from "../../services/theme"
import ClaimChannel from "../dialogs/claim-channel"
import IdentityEditor from "../identity-editor"
import { DonationAddressForm } from "../settings/donation-address-form"
import { navigate } from "@reach/router"
import { useUser } from "../../hooks/use-user"

const HowToMonetize: FunctionComponent = () => {
  const { setContent } = useModal()
  const { user, isLoggedIn, isSignedUp, logout, openLoginPrompt } = useUser()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    primaryBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.primary,
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,

    headerBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
  }))

  const gotoPage = (event: any, path: string) => {
    if (event) event.preventDefault()
    navigate(path)
  }

  return (
    <div>
      <h3>How to set up content monetization</h3>
      <p>1. Set up a Smartlike account</p>
      <ul>
        <li>
          <span
            className="intext-link"
            onClick={() => {
              openLoginPrompt(false)
            }}
          >
            create an account
          </span>{" "}
          and add funds to it via your favorite creator or project
        </li>
        <li>
          <span
            className="intext-link"
            onClick={() => {
              setContent(<IdentityEditor close={() => setContent(null)} />)
            }}
          >
            publish an identity
          </span>{" "}
          to help your audience recognize you (handle and name are used similar
          to Twitter)
        </li>
        <li>
          <span
            className="intext-link"
            onClick={() => {
              setContent(
                <DonationAddressForm
                  close={(account: any) => {
                    setContent(null)
                  }}
                />
              )
            }}
          >
            add your donation address
          </span>{" "}
          to help your audience recognize you (handle and name are used similar
          to Twitter). If you don't plan to maintain your own payment gateway
          choose one you trust. For more privacy options{" "}
          <a
            href="https://github.com/smartlike-org/smartlike/tree/main/crates/gateway"
            target="_blank"
          >
            check
          </a>
          .
        </li>
      </ul>
      <p>
        <br />
      </p>
      <p>
        2. Connect your internet content to the Smartlike account
        <ul>
          <li>
            Enter your page URL into the search field and press Enter. If the
            page hasn't been liked yet, press the thumb-up to the right and
            search again after a few seconds.
          </li>
          <li>
            Select "Edit" | "Claim Ownership" from the three dot menu for your
            content item. Follow platform dependent instructions that will look
            like this:
          </li>
        </ul>
      </p>
      <div className="dialog-frame">
        <ClaimChannel
          channel={{
            kind: 2,
            id: "John Smith#example.com",
            alias: "John Smith#example.com",
            owner: "docs",
          }}
          platform={""}
          target={""}
          close={() => setContent(null)}
        />
      </div>
      <p>
        When subscribing to a channel, users can choose whether they want to
        follow a creator on a particular platform or everywhere. Check{" "}
        <a
          className="intext-link"
          href="/docs/platform-independent-creator-channels"
          onClick={e => {
            gotoPage(e, "/docs/platform-independent-creator-channels")
          }}
        >
          super channels
        </a>{" "}
        for more details.
      </p>
      <p>
        <br />
      </p>
      <p>
        <ul>
          <li>
            Internet domains can be connected by selecting "Edit" | "Claim
            Ownership" from the three dot menu for your domains in the Websites
            feed. You can specify a share (0-100%) to receive from the hosted
            content. Example:
          </li>
        </ul>
      </p>
      <div className="dialog-frame">
        <ClaimChannel
          channel={{ kind: 1, id: "example.com", owner: "docs" }}
          platform={""}
          target={""}
          close={() => setContent(null)}
        />
      </div>

      <p>
        <br />
      </p>
      <style jsx>{`
        .dialog-frame {
          border: 2px solid ${css.borderColor};
          border-radius: 5px;
          padding: 5px;
        }
        .intext-link {
          text-decoration: underline;
          cursor: pointer;
        }
      `}</style>
    </div>
  )
}

export default HowToMonetize
