import { signHex, getPublicKey, SeedPhrase } from "../services/crypto"
import { navigate } from "gatsby"
import { User, SignedTransaction, Transaction } from "../types"
import { showToast } from "../components/status-notification"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { useCallback, useEffect, useState } from "react"
import { useLoginModal } from "./use-login-modal"
import LoginPrompt from "../components/login-prompt"
import React from "react"

const url = process.env.NETWORK_URL

export const useNode = () => {
  const [pendingCall, setPendingCall] = useState(null)
  const { setContent } = useLoginModal()

  const getProfile = useCallback(
    async (key: string, callBack: (res: any, pk: string) => void) => {
      const [pk, pkHex] = getPublicKey(key)
      rpc_impl("get_account_data", pkHex, "", key, function (res) {
        callBack(res, pk)
      })
    },
    []
  )

  const rpc = useCallback(
    async (
      method: string,
      data: string,
      user: User | null,
      key: string | null,
      callBack: (arg0: { status: string; data: any }) => void
    ) => {
      if (key == null || key == "") {
        setPendingCall({ method: method, data: data, callBack: callBack })
        //console.log("*** rpc: not logged in " + pendingCall)

        setContent(
          React.createElement(
            LoginPrompt,
            {
              closeTo: () => {
                setContent(null)
              },
              initialCreate: false,
              pendingCall: { method: method, data: data, callBack: callBack },
            },
            null
          )
        )
        //console.log("*** rpc: not logged in " + pendingCall)
        return
      }

      if (method == "test_donate") {
        let msg = JSON.parse(data)
        const [pk, pkHex] = getPublicKey(key)
        msg.pk = pkHex
        rpc_impl(method, JSON.stringify(msg), null, key, function (res) {
          callBack(res)
        })

        return
      } else if (method == "get_messages" || method == "get_dm_public_key") {
        rpc_impl(method, data, user, key, function (res) {
          callBack(res)
        })

        return
      }

      if (user && user.id == "") {
        getProfile(key, function (res, pk) {
          if (res.status == "ok") {
            user.id = res.data.id
            rpc_impl("get_balance", user.id, user, key, function (res) {
              if (res.status == "ok") {
                let ts = Math.floor(Date.now() / 1000)
                let tx = {
                  kind: method,
                  ts: ts,
                  data: data,
                }
                rpc_impl(method, JSON.stringify(tx), user, key, callBack)
              }
            })
          } else {
            showToast(
              faInfoCircle,
              "Smartlike network",
              "Please add funds to the account."
            )
          }
        })
      } else {
        let ts = Math.floor(Date.now() / 1000)
        let tx = {
          kind: method,
          ts: ts,
          data: data,
        }
        rpc_impl(method, JSON.stringify(tx), user, key, callBack)
      }
    },
    [pendingCall, setPendingCall]
  )

  const rpc_impl = useCallback(
    async (
      method: string,
      data: string,
      user: User | null,
      key: string | null,
      callBack: (arg0: { status: string; data: any }) => void
    ) => {
      const sig = signHex(data, key) //await sign_string(data, key)
      const message = {
        jsonrpc: "2.0",
        method: method,
        id: 1234,
        params: {
          signed_message: {
            sender: user ? user.id : "",
            signature: sig,
            data: data,
          },
        },
      }

      fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(message),
      })
        .then(res => {
          status = res.status
          return res.json()
        })
        .then(jsonData => {
          //console.log(jsonData)
          //console.log(status)

          if (callBack) {
            if (status == 200) {
              callBack(jsonData)
            } else {
              callBack({
                status: "failed",
                data: status,
              })
            }
          }
        })
        .catch(err => {
          if (callBack) {
            callBack({
              status: "failed",
              data: err,
            })
          }
        })
    },
    []
  )

  return {
    pendingCall,
    setPendingCall,
    getProfile,
    rpc,
    rpc_impl,
  }
}
