import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { generateMnemonic, validateMnemonic } from "../services/crypto"
import { useUser } from "../hooks/use-user"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faSyncAlt, faCopy } from "@fortawesome/pro-light-svg-icons"
import { navigate } from "gatsby"
import { useStyle } from "../hooks/use-style"
import { lightTheme, darkTheme } from "../services/theme"
import { useMedia } from "../hooks/use-media"
import { useLocation } from "@reach/router"
import { extension_login } from "../services/ext"

type Props = {
  closeTo: () => void
  initialCreate: boolean
  pendingCall: any | null
}

const LoginPrompt: FunctionComponent<Props> = ({
  closeTo,
  initialCreate,
  pendingCall,
}) => {
  const {
    user,
    login1,
    setType,
    accountState,
    setAccountState,
    extensionInstalled,
  } = useUser()
  const [secret, setSecret] = useState("")
  const [changed, setChanged] = useState(false)
  const [backup, setSetBackup] = useState(false)
  const [invalidSecret, setInvalidSecret] = useState(false)
  const { search } = useLocation()
  //const [create, setCreate] = useState(search == "?create=1")
  const [create, setCreate] = useState(initialCreate)
  const [generatedSecret, setGeneratedSecret] = useState("")

  const isChrome =
    typeof navigator != "undefined"
      ? /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor)
      : false

  const onSubmit = useCallback(
    (event: MouseEvent | null) => {
      if (secret.length == 0) {
        if (event) event.preventDefault()
        return
      }

      if (!validateMnemonic(secret)) {
        if (event) event.preventDefault()
        setInvalidSecret(true)
        return
      } else {
        setInvalidSecret(false)
      }

      login1(secret, true, pendingCall).then(res => {
        if (search == "?extension=1") {
          window.close()
        } else if (user?.balance.balance == 0) {
          setAccountState(30)
          navigate("/donate", { replace: true })
        } else {
          setAccountState(15)
          if (closeTo) closeTo()
        }
      })
      if (event) event.preventDefault()
    },
    [secret, accountState, user, setInvalidSecret]
  )

  const onAnimationStart = useCallback(
    event => {
      //console.log("onAnimationStart " + event.animationName)
      if ("onautofillstart" === event.animationName) {
        onSubmit(null)
      }
    },
    [secret]
  )

  const onSubmitAccountType = useCallback(
    (accountType: string, to: string) => {
      console.log(
        "onSubmitAccountType " + user?.account_type + " " + accountType
      )
      if (user?.account_type != accountType) {
        setChanged(true)
        if (accountType == "global") {
          setAccountState(10)
        }
        //setType(accountType)
      }
      if (accountType == "local") {
        setType(accountType)
        setAccountState(-10)
        if (pendingCall != null) {
          pendingCall.callBack({ status: "ok" })
        }

        if (closeTo) closeTo()
        //navigate(to, { replace: true })
      }
    },
    [user]
  )

  const generateKey = useCallback(() => {
    generateMnemonic().then(secret => {
      setGeneratedSecret(secret)
      /*if (isChrome == false)
          document.getElementById("password-init").value = secret
        else if (navigator && navigator.clipboard)
          navigator.clipboard.writeText(secret)*/
      /*document.getElementById("create-btn").innerHTML =
          "regenerate a new password"*/
      setSetBackup(true)
    })
  }, [])

  const copyKey = useCallback(() => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(generatedSecret)
    }
  }, [generatedSecret])

  const openCreateDialog = useCallback(() => {
    setCreate(true)
  }, [])

  const css = useStyle(theme => ({
    borderColor: theme === "light" ? lightTheme.color.border : "transparent",
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  useEffect(() => {
    if (create) {
      generateKey()
    }
  }, [create])

  /*
    useEffect(() => {
      if (donationAddressesExpanded && state.items.length == 0) fetchMoreData()
    }, [donationAddressesExpanded])
  */

  return (
    <section>
      <div style={{ float: "right", color: "grey" }}>
        <div
          onClick={() => {
            if (search == "?extension=1") {
              window.close()
            } else if (closeTo) closeTo()
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>

      {create ? (
        <div className="header">
          <div
            style={{
              marginBottom: "30px",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            Create account
          </div>

          <div>
            1. Create a mnemonic key: either use a reandomly generated one in
            the field below or manually pick 12 random words from{" "}
            <a
              className="links"
              href="https://smartlike.org/etc/wordlist.html"
              target="_blank"
            >
              this list
            </a>
            .
          </div>
          <div
            style={{
              height: "50px",
              marginTop: "10px",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            {backup == true && (
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    fontSize: "14px",
                    padding: "5px",
                    width: "100%",
                  }}
                >
                  <code>{generatedSecret}</code>
                </div>
                <div style={{ paddingTop: "5px" }}>
                  <div className="btn-container" onClick={generateKey}>
                    <FontAwesomeIcon className="" icon={faSyncAlt} />
                  </div>
                  {isChrome && false && (
                    <div className="btn-container" onClick={copyKey}>
                      <FontAwesomeIcon className="" icon={faCopy} />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              marginBottom: "20px",
            }}
          >
            2. Enter the key below and store it in your password manager
            {isChrome && extensionInstalled == false && (
              <>
                {" "}
                and/or{" "}
                <a
                  className="links"
                  href="https://chrome.google.com/webstore/detail/smartlike/hbeaghhbggdilbedobkfhneaajmnfipc"
                  target="_blank"
                >
                  browser extension
                </a>
              </>
            )}
            :
            <div>
              <input
                id="password-init"
                className="text-input"
                type="password"
                name="account_key"
                ref={input => {}}
                onChange={evt => setSecret(evt.target.value)}
                onAnimationStart={evt => onAnimationStart(evt)}
              />
            </div>
          </div>

          <div>
            3. Choose a donation recipient you trust and add funds to your
            account using their payment gateway.
          </div>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button
              style={{ whiteSpace: "nowrap", width: "200px" }}
              onClick={e => {
                onSubmit(e)
                navigate("/donate", { replace: true })
              }}
            >
              Add funds &gt;
            </button>
          </div>
        </div>
      ) : (
        accountState <= 0 && (
          <div className="header">
            <div>
              Would you like to use a Smartlike account and turn your likes into
              micro-donations or just store them locally?
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <button
                style={{ whiteSpace: "nowrap", width: "100px" }}
                onClick={e => onSubmitAccountType("global")}
              >
                Login
              </button>
              <button
                style={{ whiteSpace: "nowrap", width: "100px" }}
                onClick={e => setCreate(true)}
              >
                Sign up
              </button>
              <button
                style={{ whiteSpace: "nowrap", width: "100px" }}
                onClick={e => onSubmitAccountType("local")}
              >
                Use locally
              </button>
            </div>
          </div>
        )
      )}

      {create == false && accountState > 0 && (
        <>
          <div className="header">
            Enter or{" "}
            <span onClick={openCreateDialog} className="links">
              create
            </span>{" "}
            an account key:
          </div>

          <form className="custom-form" method="post">
            <div>
              <input
                id="password-init"
                className="text-input"
                type="password"
                name="account_key"
                ref={input => {}}
                onChange={evt => setSecret(evt.target.value)}
                onAnimationStart={evt => onAnimationStart(evt)}
              />
            </div>
            <div
              style={{
                marginTop: "5px",
                color: "red",
                height: "20px",
              }}
            >
              {invalidSecret && (
                <>
                  Invalid key. The phrase should consist of 12 words from{" "}
                  <a
                    className="links"
                    href="https://smartlike.org/etc/wordlist.html"
                    target="_blank"
                  >
                    this list
                  </a>
                  .
                </>
              )}
            </div>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <button
                id="password-init-submit"
                type="submit"
                onClick={e => onSubmit(e)}
              >
                Submit
              </button>
            </div>

            {isChrome && extensionInstalled == false && user && (
              <div className="info">
                The{" "}
                <a
                  className="links"
                  href="https://chrome.google.com/webstore/detail/smartlike/hbeaghhbggdilbedobkfhneaajmnfipc"
                  target="_blank"
                >
                  browser extension
                </a>{" "}
                can help skip this prompt.
              </div>
            )}
          </form>
        </>
      )}

      <style jsx>{`
        section {
          padding: 1em 1em 0.5em;
          font-weight: normal;
          font-family: system-ui;
          z-index: 100000;
        }
        .close {
          cursor: pointer;
        }

        .btn-container {
          padding: 3px;
          cursor: pointer;
        }
        .links {
          cursor: pointer;
          text-decoration: underline;
          text-underline-offset: 2px;
          outline: none;
          user-select: none;
        }
        .generate {
          cursor: pointer;
          /*display: inline;
              margin-left: 20px;*/
          text-decoration: underline;
          /*line-height: 14px;*/
          text-align: left;
        }
        .custom-form {
          margin-top: 10px;
        }
        .options {
          line-height: 22px;
        }
        .dialog {
          font-weight: normal;
          font-family: system-ui;
          max-width: 600px;
          width: 100%;
          z-index: 20000;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          padding: 20px;
          border: 1px solid ${css.borderColor};
          border-radius: 5px;

          background-color: #fff;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
        .header {
          margin-top: 40px;
          font-family: system-ui;
        }
        h2 {
          text-align: center;
          font-size: 20px;
        }
        button {
          padding: 5px;
          margin: 10px;
          width: 80px;
        }

        .input-container {
          flex: 1;
          /*border-radius: 2em;*/
          background-color: ${css.inputBackground};
          border: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          display: flex;
          position: relative;
          margin-top: 10px;
        }

        /*
              .text-input {
                width: 100%;
                height: 100%;
                margin: auto;
                background-color: ${css.inputBackground};
                color: inherit;
                border: none;
                padding-left: 0.5em;
                padding-right: 0.5em;
                flex: 1;
                font: inherit;
                outline: none;
              }*/

        .text-input {
          width: 100%;
          height: 100%;
          margin: auto;
          background-color: ${css.inputBackground};
          border: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          color: inherit;
          padding: 5px;
          margin-top: 5px;
          flex: 1;
          font: inherit;
          outline: none;
          line-height: 18px;
          height: 36px;
          box-sizing: border-box;
        }

        .info {
          /*text-decoration: none;*/
          margin-top: 14px;
          /*font-size: 14px;*/
        }
        .info-link {
          color: blue;
        }
        .radio-input {
          margin-right: 10px;
        }
        .choice {
          /*padding: 5px;*/
          display: inline;
          outline: none;
        }
        .choice:hover {
          cursor: pointer;
          /*background-color: ${css.inputBackground};*/
        }
      `}</style>
    </section>
  )
}

export default LoginPrompt
