import {
  FunctionComponent,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from "react"
import React from "react"
import {
  Button,
  Grid,
  TextField,
  makeStyles,
  createStyles,
} from "@material-ui/core"
import { countries as COUNTRIES, languages as LANGUAGES } from "countries-list"
import SettingsTextField from "./settings-text-field"
import { getCachedSecret, useUser } from "../hooks/use-user"
import { showToast } from "../components/status-notification"
import { useNode } from "../hooks/use-node"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type Props = {
  close: () => void
}

const IdentityEditor: FunctionComponent = ({ close }) => {
  const [profile, setProfile] = useState({
    name: "",
    title: "",
    description: "",
    image: "",
    icon: "",
    cut: 10,
    url: "",
    country: "",
    language: "",
    aliases: [],
    timestamp: 0,
    thanks: "",
  })

  const classes = useStyles()
  const { user, updateBalance, updateProfile } = useUser()
  const { rpc } = useNode()

  const countries = useMemo(() => {
    let c = Object.entries(COUNTRIES)
      .map(([k, v]) => ({
        label: v.name,
        value: k,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
    c.push({ label: "", value: "" })
    return c
  }, [])

  const languages = useMemo(() => {
    let l = Object.entries(LANGUAGES)
      .map(([k, v]) => ({
        label: v.name,
        value: k,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))

    l.push({ label: "", value: "" })
    return l
  }, [])

  const handleChange = useCallback(
    event => {
      event.preventDefault()
      setProfile({
        ...profile,
        [event.target.name]: event.target.value,
      })
    },
    [profile]
  )

  const registerName = () => {
    if (profile.name.length != 0) {
      profile.timestamp = Math.round(new Date().getTime() / 1000)
      rpc(
        "register_name",
        JSON.stringify(profile),
        user,
        getCachedSecret(),
        function (res) {
          let message
          if (res.status == "ok") {
            if (user) {
              updateProfile(profile)
              updateBalance(-0.01 * user?.displayed_currency_rate)
            }
            message = "Profile updated"
          } else {
            message = "Profile update failed"
          }
          showToast(faInfoCircle, "Smartlike network", message)
        }
      )
    }
  }

  useEffect(() => {
    if (user && user.profile) {
      setProfile(user.profile)
    }
  }, [user])

  return (
    <div>
      <div style={{ float: "right", color: "grey" }}>
        <div
          onClick={() => {
            if (close) close()
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>

      <table className={classes.accountTable}>
        <tbody>
          <tr>
            <td style={{ width: "90px" }}>Account:</td>
            <td>
              <div className={classes.account}>{user?.id}</div>
            </td>
          </tr>
          <tr style={{ color: "rgb(129, 129, 129)" }}>
            <td>Public key:</td>
            <td>
              <div className={classes.account}>{user?.pk}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <Grid container>
          <Grid item xs={12} sm={6} className={classes.leftColumn}>
            <SettingsTextField
              key="account"
              fullWidth
              helperText=""
              label="@account"
              margin="dense"
              name="name"
              onChange={handleChange}
              value={profile.name}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.rightColumn}>
            <SettingsTextField
              key="name"
              id="name1"
              fullWidth
              helperText=""
              label="name"
              margin="dense"
              name="title"
              onChange={handleChange}
              value={profile.title}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.leftColumn}>
            <TextField
              fullWidth
              helperText=""
              label="avatar url"
              margin="dense"
              name="image"
              onChange={handleChange}
              value={profile.image}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.rightColumn}>
            <TextField
              fullWidth
              helperText=""
              label="home page"
              margin="dense"
              name="url"
              onChange={handleChange}
              value={profile.url}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} className={classes.span}>
            <TextField
              fullWidth
              helperText=""
              label="description"
              margin="dense"
              name="description"
              onChange={handleChange}
              value={profile.description}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.leftColumn}>
            <TextField
              InputProps={{ style: { fontSize: 14 } }}
              fullWidth
              label="country"
              margin="dense"
              name="country"
              onChange={handleChange}
              variant="outlined"
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={profile.country}
            >
              {countries.map(c => (
                <option key={c.value} value={c.value}>
                  {c.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.rightColumn}>
            <TextField
              InputProps={{ style: { fontSize: 14 } }}
              fullWidth
              label="language"
              margin="dense"
              name="language"
              onChange={handleChange}
              variant="outlined"
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={profile.language}
            >
              {languages.map(c => (
                <option key={c.value} value={c.value}>
                  {c.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.span}>
          <TextField
            fullWidth
            helperText=""
            label="text to reply to donations"
            margin="dense"
            name="thanks"
            onChange={handleChange}
            value={profile.thanks}
            variant="outlined"
          />
        </Grid>

        <div className={classes.controls} style={{ paddingBottom: 0 }}>
          <Grid container>
            <span className={classes.spacer} />
            <div>
              <Button
                variant="contained"
                className={classes.btn}
                onClick={registerName}
              >
                Publish
              </Button>
            </div>
            <span className={classes.spacer} />
          </Grid>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      cursor: "pointer",
    },

    comments: {
      width: "100%",
      height: "80px",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      borderRadius: "4px",
      padding: "10px",
      marginBottom: "30px",
      fontSize: "12px",
      fontWeight: 400,
    },
    spacer: {
      flexGrow: 1,
    },
    controls: {
      display: "flex",
      marginTop: "10px",
      marginBottom: "10px",
    },
    separator: {
      width: "100%",
      borderBottom: "1px solid #eeeeee",
      marginTop: "5px",
      marginBottom: "10px",
    },
    table: {
      width: "100%",
    },
    cell: {
      margin: "auto",
    },
    scroll: {
      width: "100%",
      fontSize: "12px",
      fontWeight: 400,
    },
    tableHeader: {
      width: "100%",
      display: "flex",
      color: "rgba(0, 0, 0, 0.54)",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    row: {
      width: "100%",
      display: "flex",
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "10px",
      paddingRight: "10px",
      borderRadius: "4px",
    },
    section: {
      maxWidth: "800px",
      margin: "auto",
      fontFamily: "Roboto",
    },
    accountTable: {
      paddingBottom: "20px",
      paddingTop: "10px",
      fontWeight: 400,
    },
    account: {
      fontWeight: 400,
      fontSize: "14px",
      wordBreak: "break-all",
    },
    chapter: {
      fontSize: "15px",
      marginBottom: "10px",
      marginTop: "10px",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    leftColumn: {
      paddingRight: "5px",
      paddingLeft: "5px",
      paddingBottom: "20px",
    },
    rightColumn: {
      paddingRight: "5px",
      paddingLeft: "5px",
      paddingBottom: "20px",
    },
    span: {
      paddingRight: "5px",
      paddingLeft: "5px",
      paddingBottom: "20px",
    },
    btn: {
      width: "100px",
      marginRight: "10px",
      marginBottom: "20px",
    },
    inputField: {
      backgroundColor: "#eeeeee",
      border: "1px solid #444444",
      borderRadius: 3,
      padding: "3px 6px",
      transition:
        "200ms background-color, 200ms border-color, 200ms box-shadow",
      minWidth: 0,
      width: "100%",
    },
    paper: {
      padding: theme.spacing(3, 3, 1),
      marginBottom: "20px",
    },
  })
)
export default IdentityEditor
