import { SignedMessage, Account } from "../types"

const send = <T extends object>(type: string, data?: any) => {
  if (typeof chrome === "undefined") {
    if (process.env.NODE_ENV !== "production") {
      console.warn("Unable to call Chrome extension in non-Chrome browser.")
    }
    return
  } else if (!process.env.CHROME_EXTENSION) {
    if (process.env.NODE_ENV !== "production") {
      console.warn("Missing Chrome extension ID")
    }
    return
  }

  return new Promise<T>((resolve, reject) => {
    chrome.runtime.sendMessage(
      process.env.CHROME_EXTENSION!,
      { type, data },
      response => {
        //console.log(response)
        return response ? resolve(response) : reject(chrome.runtime.lastError)
      }
    )
  })
}

export const SEND_getAccount = () =>
  send<{ name: string }>("get-account")?.then(response => response.name)

export const SEND_sign = (payload: string) =>
  send<SignedMessage>("sign", payload)

export const extension_login = (payload: string) =>
  send<{ status: string }>("login", payload)

/*  
  chrome.runtime.sendMessage(
    process.env.CHROME_EXTENSION!,
    { type: "login", data: payload },
    response => {
      console.log(response)
      //return response ? resolve(response) : reject(chrome.runtime.lastError)
    }
  )
*/

export const get_account = () => send<Account>("get-account")

/*{
  //send<{ status: string }>("login", payload)
  //console.log(process.env.CHROME_EXTENSION)
  chrome.runtime.sendMessage(
    process.env.CHROME_EXTENSION!,
    { type: "get-account", data: "" },
    response => {
      //console.log(response)
      //return response ? resolve(response) : reject(chrome.runtime.lastError)
    }
  )
}*/
