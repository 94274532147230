import {
  faCommentDollar,
  faCalendarPlus,
  faTags,
  faLink,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback, useMemo, useRef } from "react"
import { useModal } from "../../hooks/use-modal"
import { useStyle } from "../../hooks/use-style"
import { lightTheme, darkTheme } from "../../services/theme"
import ClaimChannel from "../dialogs/claim-channel"
import IdentityEditor from "../identity-editor"
import { DonationAddressForm } from "../settings/donation-address-form"
import { navigate } from "@reach/router"
import { useUser } from "../../hooks/use-user"

const Embed: FunctionComponent = () => {
  const { setContent } = useModal()
  const { openLoginPrompt } = useUser()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    primaryBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.primary,
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,

    headerBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
  }))

  const gotoPage = (event: any, path: string) => {
    if (event) event.preventDefault()
    navigate(path)
  }

  return (
    <div>
      <iframe
        src="https://docs.smartlike.org/embed.html"
        style={{ width: "100%", height: 2700 }}
        frameBorder="0"
      ></iframe>
    </div>
  )
}

export default Embed
