import {
  faCommentDollar,
  faCalendarPlus,
  faTags,
  faLink,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react"
import { useModal } from "../../hooks/use-modal"
import { useStyle } from "../../hooks/use-style"
import { lightTheme, darkTheme } from "../../services/theme"
import ClaimChannel from "../dialogs/claim-channel"
import IdentityEditor from "../identity-editor"
import { DonationAddressForm } from "../settings/donation-address-form"
import { navigate } from "@reach/router"
import { useUser } from "../../hooks/use-user"

const HowToConnectTelegram: FunctionComponent = () => {
  const { setContent } = useModal()
  const { openLoginPrompt, user } = useUser()
  const [amount, setAmount] = useState(0.01)

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    primaryBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.primary,
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,

    headerBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
  }))

  const connectBot = useCallback(() => {
    //if (event) event.preventDefault()
    //navigate(path)
    if (user && user.id) {
      window.open(
        "https://t.me/smartlike_org_bot?start=" +
          user.id +
          "_" +
          Math.round(amount * 100),
        "_blank"
      )
    }
  }, [user, amount])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length == 0) {
      setAmount(0.01)
    } else {
      let a = parseFloat(event.currentTarget.value)
      if (a <= 0) a = 0.01
      setAmount(a)
    }
  }, [])

  return (
    <div>
      <h3>How to connect Telegram</h3>
      <h4>Send micro-donations</h4>
      <p>
        Smartlike bot integrates micro-donation processing into Telegram.
        Forward your favorite posts to the bot to support authors and help other
        users discover great content.
      </p>
      <ol>
        <li>
          <p>
            If you don't have one yet,{" "}
            <span
              className="intext-link"
              onClick={() => {
                openLoginPrompt(false)
              }}
            >
              create a Smartlike account
            </span>{" "}
            and add funds to it via your favorite creator.
          </p>
        </li>
        <li>
          <p style={{ marginBottom: 0 }}>
            Choose a default donation amount. When you forward a post you like
            to the bot, the amount will be donated. You can change it anytime.
          </p>
          <p className="trow">
            <div className="amountCell">
              <div style={{ width: "100px", display: "inline-flex" }}>
                <input
                  id="amount"
                  type="number"
                  step="0.01"
                  value={amount}
                  onChange={handleChange}
                  className="amount"
                />
              </div>
            </div>
            <div className="tcell">USD</div>
          </p>
        </li>
        <li>
          <p>
            <span
              className="intext-link"
              onClick={() => {
                connectBot()
              }}
            >
              Connect to smartlike_org_bot
            </span>
            .
          </p>
        </li>
      </ol>

      <p>
        <br />
      </p>
      <h4>Receive micro-donations</h4>
      <p>
        Add Smartlike signature to one of the posts on your channel{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          (e.g. &quot;Smartlike: a167fbe3-049a-cc89-ca56-d4f2ad502d56&quot;)
        </span>{" "}
        and{" "}
        <span
          className="intext-link"
          onClick={() => {
            navigate("/docs/how-to-setup-content-monetization")
          }}
        >
          claim
        </span>{" "}
        channel ownership.
      </p>
      <style jsx global>{`
        li {
          margin-bottom: 15px;
        }
        h4 {
          font-size: 18px;
          font-weight: 500;
        }
      `}</style>

      <style jsx>{`
        .dialog-frame {
          border: 2px solid ${css.borderColor};
          border-radius: 5px;
          padding: 5px;
        }
        .intext-link {
          text-decoration: underline;
          cursor: pointer;
        }

        .amountCell,
        .commentCell {
          display: table-cell;
          vertical-align: middle;
        }

        .amount,
        .comment {
          width: 100%;
          text-align: right;
          font-family: "Roboto";
          font-size: 16px;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        .amount:focus,
        .amount:hover,
        .commetn:focus,
        .comment:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }

        .amount {
          margin-right: 10px;
        }
        .trow {
          display: table-row;
        }
        .tcell {
          display: table-cell;
          vertical-align: middle;
        }
      `}</style>
    </div>
  )
}

export default HowToConnectTelegram
