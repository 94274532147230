import {
  faCommentDollar,
  faCalendarPlus,
  faTags,
  faLink,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback, useMemo, useRef } from "react"
import { useModal } from "../../hooks/use-modal"
import { useStyle } from "../../hooks/use-style"
import { lightTheme, darkTheme } from "../../services/theme"
import ClaimChannel from "../dialogs/claim-channel"
import IdentityEditor from "../identity-editor"
import { DonationAddressForm } from "../settings/donation-address-form"
import { navigate } from "@reach/router"
import { useUser } from "../../hooks/use-user"

const HowToSupport: FunctionComponent = () => {
  const { setContent } = useModal()
  const { openLoginPrompt } = useUser()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    primaryBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.primary,
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,

    headerBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
  }))

  const gotoPage = (event: any, path: string) => {
    if (event) event.preventDefault()
    navigate(path)
  }

  return (
    <div>
      <h3>How to support your favorite creators</h3>
      <p>
        1.{" "}
        <span
          className="intext-link"
          onClick={() => {
            openLoginPrompt(false)
          }}
        >
          Create an account
        </span>{" "}
        and add funds to it via your favorite creator
      </p>
      <p>
        2. Choose a way to support:
        <ul>
          <li>
            <b>donate</b>: click{" "}
            <FontAwesomeIcon icon={faCommentDollar} size="sm" /> under a content
            item and optionally leave a message. Creator receives 100% of your
            transfer. You can also subscribe for a monthly payment when you
            click on creator name and{" "}
            <FontAwesomeIcon icon={faCalendarPlus} size="sm" /> on the right
            panel.
          </li>
          <li>
            <b>smartlike</b>: when you click on thumb-up buttons you make a
            donation and increase channel visibility in the charts to help other
            users find the content item. 5% cut of the donation is evenly
            distributed among all creators to mitigate fake likes: if abusers
            should like themselves they compensate those they play against.
          </li>
          <li>
            <b>comment</b>: by commenting you increase content visibility, make
            a $0.01 donation to content item (anti-spam protection) and earn
            money - when other users upvote your comments their donations go to
            your account, when they downvote - to the content item.
          </li>
          <li>
            <b>curate</b> content to boost its visibility: help set tags{" "}
            <FontAwesomeIcon icon={faTags} size="sm" /> to make content more
            visible in search and bookmarks. More popular tag sets win and get
            published.
          </li>
          <li>
            <b>moderate</b>: select "Edit" | "Mark as" from the three dot menu
            for your content items in the Charts feed to mark content that you
            would like to unsee and save other users from seeing it if they have
            the corresponding{" "}
            <a
              className="intext-link"
              href="/settings"
              onClick={e => {
                gotoPage(e, "/settings")
              }}
            >
              filters
            </a>{" "}
            set.
          </li>
          <li>
            <b>link super channels</b>: click on a channel label, hit
            "Subscribe" in the right menu, select "
            <a
              className="intext-link"
              href="/docs/platform-independent-creator-channels"
              onClick={e => {
                gotoPage(e, "/docs/platform-independent-creator-channels")
              }}
            >
              Super channel
            </a>
            " . The feed will show creator's content from all platforms. If you
            find their content on a new platform that doesn't show up in the
            super channel feed, click{" "}
            <FontAwesomeIcon icon={faLink} size="sm" /> to add. This will
            increase creator visibility and make them less dependent from
            platforms.{" "}
          </li>
        </ul>
      </p>

      <p>
        <br />
      </p>
      <style jsx global>{`
        li {
          margin-bottom: 15px;
        }
      `}</style>

      <style jsx>{`
        .dialog-frame {
          border: 2px solid ${css.borderColor};
          border-radius: 5px;
          padding: 5px;
        }
        .intext-link {
          text-decoration: underline;
          cursor: pointer;
        }
      `}</style>
    </div>
  )
}

export default HowToSupport
