import { useState, ReactNode, useEffect } from "react"
import { useLocation } from "@reach/router"
import { createSingletonHook } from "./create-singleton-hook"

export const [useModal, ModalProvider] = createSingletonHook(() => {
  const [content, setContent] = useState<ReactNode>(null)
  const { pathname } = useLocation()
  /**
   * Close on page change
   */
  useEffect(() => {
    setContent(null)
  }, [pathname])

  return {
    content,
    setContent,
  }
})
