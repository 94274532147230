exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---docs-about-mdx": () => import("./../../../docs/about.mdx" /* webpackChunkName: "component---docs-about-mdx" */),
  "component---docs-business-model-mdx": () => import("./../../../docs/business-model.mdx" /* webpackChunkName: "component---docs-business-model-mdx" */),
  "component---docs-charts-and-search-mdx": () => import("./../../../docs/charts-and-search.mdx" /* webpackChunkName: "component---docs-charts-and-search-mdx" */),
  "component---docs-decentralized-crowdsourced-moderation-mdx": () => import("./../../../docs/decentralized-crowdsourced-moderation.mdx" /* webpackChunkName: "component---docs-decentralized-crowdsourced-moderation-mdx" */),
  "component---docs-embed-mdx": () => import("./../../../docs/embed.mdx" /* webpackChunkName: "component---docs-embed-mdx" */),
  "component---docs-fake-likes-mdx": () => import("./../../../docs/fake-likes.mdx" /* webpackChunkName: "component---docs-fake-likes-mdx" */),
  "component---docs-financial-system-mdx": () => import("./../../../docs/financial-system.mdx" /* webpackChunkName: "component---docs-financial-system-mdx" */),
  "component---docs-how-to-connect-telegram-mdx": () => import("./../../../docs/how-to-connect-telegram.mdx" /* webpackChunkName: "component---docs-how-to-connect-telegram-mdx" */),
  "component---docs-how-to-setup-content-monetization-mdx": () => import("./../../../docs/how-to-setup-content-monetization.mdx" /* webpackChunkName: "component---docs-how-to-setup-content-monetization-mdx" */),
  "component---docs-how-to-support-your-favorite-creators-mdx": () => import("./../../../docs/how-to-support-your-favorite-creators.mdx" /* webpackChunkName: "component---docs-how-to-support-your-favorite-creators-mdx" */),
  "component---docs-moderation-policy-mdx": () => import("./../../../docs/moderation-policy.mdx" /* webpackChunkName: "component---docs-moderation-policy-mdx" */),
  "component---docs-overview-mdx": () => import("./../../../docs/overview.mdx" /* webpackChunkName: "component---docs-overview-mdx" */),
  "component---docs-privacy-mdx": () => import("./../../../docs/privacy.mdx" /* webpackChunkName: "component---docs-privacy-mdx" */),
  "component---docs-super-channels-mdx": () => import("./../../../docs/super-channels.mdx" /* webpackChunkName: "component---docs-super-channels-mdx" */),
  "component---docs-technology-mdx": () => import("./../../../docs/technology.mdx" /* webpackChunkName: "component---docs-technology-mdx" */),
  "component---docs-terms-mdx": () => import("./../../../docs/terms.mdx" /* webpackChunkName: "component---docs-terms-mdx" */),
  "component---src-components-docs-page-layout-tsx": () => import("./../../../src/components/docs-page-layout.tsx" /* webpackChunkName: "component---src-components-docs-page-layout-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-allcomments-tsx": () => import("./../../../src/pages/allcomments.tsx" /* webpackChunkName: "component---src-pages-allcomments-tsx" */),
  "component---src-pages-audit-tsx": () => import("./../../../src/pages/audit.tsx" /* webpackChunkName: "component---src-pages-audit-tsx" */),
  "component---src-pages-authors-tsx": () => import("./../../../src/pages/authors.tsx" /* webpackChunkName: "component---src-pages-authors-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-channel-tsx": () => import("./../../../src/pages/channel.tsx" /* webpackChunkName: "component---src-pages-channel-tsx" */),
  "component---src-pages-comments-tsx": () => import("./../../../src/pages/comments.tsx" /* webpackChunkName: "component---src-pages-comments-tsx" */),
  "component---src-pages-confirm-tsx": () => import("./../../../src/pages/confirm.tsx" /* webpackChunkName: "component---src-pages-confirm-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs_.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integration-tsx": () => import("./../../../src/pages/integration.tsx" /* webpackChunkName: "component---src-pages-integration-tsx" */),
  "component---src-pages-item-tsx": () => import("./../../../src/pages/item.tsx" /* webpackChunkName: "component---src-pages-item-tsx" */),
  "component---src-pages-log-tsx": () => import("./../../../src/pages/log.tsx" /* webpackChunkName: "component---src-pages-log-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-moderators-tsx": () => import("./../../../src/pages/moderators.tsx" /* webpackChunkName: "component---src-pages-moderators-tsx" */),
  "component---src-pages-muted-tsx": () => import("./../../../src/pages/muted.tsx" /* webpackChunkName: "component---src-pages-muted-tsx" */),
  "component---src-pages-rates-tsx": () => import("./../../../src/pages/rates.tsx" /* webpackChunkName: "component---src-pages-rates-tsx" */),
  "component---src-pages-reload-tsx": () => import("./../../../src/pages/reload.tsx" /* webpackChunkName: "component---src-pages-reload-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-subscriptions-tsx": () => import("./../../../src/pages/subscriptions.tsx" /* webpackChunkName: "component---src-pages-subscriptions-tsx" */),
  "component---src-pages-superchannels-tsx": () => import("./../../../src/pages/superchannels.tsx" /* webpackChunkName: "component---src-pages-superchannels-tsx" */),
  "component---src-pages-supporters-tsx": () => import("./../../../src/pages/supporters.tsx" /* webpackChunkName: "component---src-pages-supporters-tsx" */),
  "component---src-pages-websites-tsx": () => import("./../../../src/pages/websites.tsx" /* webpackChunkName: "component---src-pages-websites-tsx" */)
}

