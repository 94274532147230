import nacl from "tweetnacl"
import { blake2b } from "blakejs"
const bip39 = require("bip39-light")

let cachedKeyAccount: string | null = null
let cachedKey: CryptoKey | null = null

/*
Convert a string into an ArrayBuffer
from https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
*/
function str2ab(str: string) {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i)
  }
  return buf
}

function ab2base64(ab: ArrayBuffer) {
  const privateKeyStr = String.fromCharCode.apply(null, new Uint8Array(ab))
  return window.btoa(privateKeyStr)
}

function buf2hex(buffer: ArrayBuffer) {
  // buffer is an ArrayBuffer
  return [...new Uint8Array(buffer)]
    .map(x => x.toString(16).padStart(2, "0"))
    .join("")
}

export const arr2hex = (buffer: Uint8Array) => {
  return [...new Uint8Array(buffer)]
    .map(x => x.toString(16).padStart(2, "0"))
    .join("")
}

export const arr2base64 = (buffer: Uint8Array) => {
  return btoa(String.fromCharCode.apply(null, buffer))
}

export class SeedPhrase {
  phrase: string
  constructor(phrase: string) {
    this.phrase = phrase
  }

  static new_random(): SeedPhrase {
    let mnemonic: string = bip39.generateMnemonic()
    return new SeedPhrase(mnemonic)
  }

  static validate(mnemonic: string): boolean {
    return bip39.validateMnemonic(mnemonic)
  }
}

export const generateMnemonic = async () => {
  const seedPhrase = SeedPhrase.new_random().phrase
  return seedPhrase
}
export const validateMnemonic = (mnemonic: string) => {
  return SeedPhrase.validate(mnemonic)
}

export const getPublicKey = (secret: string) => {
  var seed = blake2b(new TextEncoder().encode(secret), undefined).slice(0, 32)
  var keys = nacl.sign.keyPair.fromSeed(seed)
  return [keys.publicKey, arr2hex(keys.publicKey)]
}

export const sign = (message: string, secret: string) => {
  var seed = blake2b(new TextEncoder().encode(secret), undefined).slice(0, 32)
  var keys = nacl.sign.keyPair.fromSeed(seed)
  var sig = nacl.sign(new TextEncoder().encode(message), keys.secretKey)
  return ab2base64(sig.subarray(0, nacl.sign.signatureLength))
}

export const signHex = (message: string, secret: string) => {
  var seed = blake2b(new TextEncoder().encode(secret), undefined).slice(0, 32)
  var keys = nacl.sign.keyPair.fromSeed(seed)
  var sig = nacl.sign(new TextEncoder().encode(message), keys.secretKey)
  return arr2hex(sig.subarray(0, nacl.sign.signatureLength))
}

export const generateDMKeys = async (secret: string) => {
  //console.log("generating keys for dm")

  return new Promise(resolve => {
    window.crypto.subtle
      .generateKey(
        {
          name: "RSA-OAEP",
          modulusLength: 4096,
          publicExponent: new Uint8Array([1, 0, 1]),
          hash: "SHA-256",
        },
        true,
        ["encrypt", "decrypt"]
      )
      .then(res => {
        crypto.subtle.exportKey("spki", res.publicKey).then(publicKey => {
          const publicKeyStr = String.fromCharCode.apply(
            null,
            new Uint8Array(publicKey)
          )
          const publicKeyBase64 = window.btoa(publicKeyStr)
          //console.log("public key: " + publicKeyBase64)

          crypto.subtle
            .exportKey("pkcs8", res.privateKey)
            .then(async privateKey => {
              const privateKeyBase64 = ab2base64(privateKey)
              //console.log("private key: " + privateKeyBase64)

              const digest = await crypto.subtle.digest(
                "SHA-256",
                new TextEncoder().encode(secret)
              )

              const iv = window.crypto.getRandomValues(new Uint8Array(12))
              window.crypto.subtle
                .importKey("raw", digest, "AES-GCM", true, ["encrypt"])
                .then(privateAESKey => {
                  const privateAesKeyStr = String.fromCharCode.apply(
                    null,
                    new Uint8Array(privateAESKey)
                  )

                  window.crypto.subtle
                    .encrypt(
                      {
                        name: "AES-GCM",
                        iv: iv,
                      },
                      privateAESKey,
                      new TextEncoder().encode(privateKeyBase64)
                    )
                    .then(encryptedPrivateKey => {
                      const encryptedPrivateKeyBase64 =
                        ab2base64(encryptedPrivateKey)
                      resolve([
                        publicKeyBase64,
                        encryptedPrivateKeyBase64,
                        ab2base64(iv),
                      ])
                    })
                })
            })
        })
      })
    return []
  })
}

export const encrypt = (message: string, publicKeyStr: string) => {
  return new Promise(resolve => {
    window.crypto.subtle
      .importKey(
        "spki",
        str2ab(window.atob(publicKeyStr)),
        {
          name: "RSA-OAEP",
          hash: "SHA-256",
        },
        true,
        ["encrypt"]
      )
      .then(key => {
        window.crypto.subtle
          .encrypt(
            {
              name: "RSA-OAEP",
            },
            key,
            new TextEncoder().encode(message)
          )
          .then(res => {
            resolve(ab2base64(res))
          })
      })
  })
}

export const decrypt = async (
  message: string,
  encryptedDMPrivateKeyStr: string,
  secret: string,
  ivStr: string,
  userid: string
) => {
  if (cachedKeyAccount == userid && cachedKey != null) {
    return new Promise(resolve => {
      window.crypto.subtle
        .decrypt("RSA-OAEP", cachedKey, str2ab(window.atob(message)))
        .then(t => {
          resolve(new TextDecoder().decode(t))
        })
        .catch(e => {
          console.log("exception: " + e)
        })
    })
  } else {
    const iv = str2ab(window.atob(ivStr))
    window.atob(encryptedDMPrivateKeyStr)
    const encryptedLoginPrivateKey = str2ab(
      window.atob(encryptedDMPrivateKeyStr)
    )

    const digest = await crypto.subtle.digest(
      "SHA-256",
      new TextEncoder().encode(secret)
    )

    return new Promise(resolve => {
      window.crypto.subtle
        .importKey("raw", digest, "AES-GCM", true, ["decrypt"])
        .then(privateAESKey => {
          window.crypto.subtle
            .decrypt(
              {
                name: "AES-GCM",
                iv: iv,
              },
              privateAESKey,
              encryptedLoginPrivateKey
            )
            .then(privateKeyBase64 => {
              const privateKeyBase64Str = String.fromCharCode.apply(
                null,
                new Uint8Array(privateKeyBase64)
              )
              //console.log("decrypted key " + privateKeyBase64Str)

              window.crypto.subtle
                .importKey(
                  "pkcs8",
                  str2ab(window.atob(privateKeyBase64Str)),
                  {
                    name: "RSA-OAEP",
                    hash: "SHA-256",
                  },
                  true,
                  ["decrypt"]
                )
                .then(key => {
                  cachedKeyAccount = userid
                  cachedKey = key
                  //console.log("decrypted RSA " + message)
                  window.crypto.subtle
                    .decrypt("RSA-OAEP", key, str2ab(window.atob(message)))
                    .then(t => {
                      resolve(new TextDecoder().decode(t))
                    })
                    .catch(e => {
                      console.log("exception: " + e)
                    })
                })
            })
          return []
        })
    })
  }
}
